import React from "react"
import styled from "styled-components"
import { motion, AnimatePresence, useAnimation } from "framer-motion"
import { Cancel } from "../../../assets/"


interface StyleProps {
  isRE: boolean
}

const StyledModel = styled(motion.div)`
  position: fixed;

  z-index: 10000000000;
  background: white;
  width: 100vw;
  height: 100vh;

  margin-top: -70px;

  border-radius: 10px;
  border: 5px solid var(--secondcolor);
`
const StyledContentWrapper = styled.div`
  overflow-y: scroll;
  display: grid;
  max-height: 98vh;
  grid-template-columns: 1fr;

  grid-template-rows: ${(props: StyleProps) =>
    props.isRE ? "auto auto 50px 1fr" : "70px 50px 1fr"};

  padding: 50px;
`

const StyledContentTitle = styled(motion.h4)`
  font-size: 1.2rem;

  margin-bottom: 15px;
`

const StyledContentCompany = styled(motion.h5)`
  font-size: 1rem;
  margin-bottom: 15px;
`

const StyledContentLocation = styled(motion.p)`
  font-style: italic;
  align-self: center;
`
const StyledContentBody = styled(motion.div)`
  line-height: 1.7rem;
  padding-bottom: 5rem;

  & p {

    margin-bottom: 20px;

    & p {
      margin-bottom: 20px;
    }
  }

  & ul {
    margin: 20px;

    & > * {

      margin-bottom: 5px;
    }
  }
`

interface Props {
  type: string
  content: {
    title: string
    company: string
    location: string
    body: string
  }
  isVisible: boolean
  setVisible: React.Dispatch<boolean>
}


export const MobileModel = ({
  type,
  content,
  isVisible,
  setVisible,
}: Props) => {

  const controls = useAnimation()

  React.useLayoutEffect(() => {
    const html = document.querySelector("html")
    const body = document.querySelector("body")

    if (isVisible) {
      body.classList.add("stopscrolling")
      html.classList.add("stopscrolling")
    } else {
      body.classList.remove("stopscrolling")
      html.classList.remove("stopscrolling")
    }
  }, [isVisible])

  React.useEffect(() => {
    controls.set({ opacity: 0 })
    controls.start(i => ({
      opacity: 1,

      transition: {
        duration: 0.7,
        delay: i * 0.3,
      },
    }))
  }, [isVisible])


  const isRE = type === "RE"

  return (
    <AnimatePresence>
      {isVisible && (
        <StyledModel
          initial={{
            scale: 0.2,
            opacity: 0,
          }}
          animate={{
            scale: 1,
            opacity: 1,
          }}
          exit={{
            scale: 0.2,
            opacity: 0,
          }}
        >
          <Cancel
            style={{
              position: "absolute",
              top: 10,
              height: "30px",
              right: 10,
            }}
            onClick={() => setVisible(false)}
          />

          <StyledContentWrapper isRE={isRE}>
            <StyledContentTitle custom={0} animate={controls}>
              {content.title}
            </StyledContentTitle>
            {isRE && (
              <StyledContentCompany custom={1} animate={controls}>
                {content.company}
              </StyledContentCompany>
            )}
            <StyledContentLocation custom={isRE ? 2 : 1} animate={controls}>
              {content.location}
            </StyledContentLocation>
            <StyledContentBody
              custom={isRE ? 3 : 2}

              animate={controls}
              dangerouslySetInnerHTML={{ __html: content.body }}
            ></StyledContentBody>
          </StyledContentWrapper>
        </StyledModel>
      )}
    </AnimatePresence>
  )
}
