import * as React from "react"
import styled from "styled-components"
import NavBarContext from "./context"
import { Logo } from "../../assets"

import { Link } from "gatsby"

type Props = {
  className?: string
}

interface styledProps {
  open: boolean
  color: string
}

const StyledTitle = styled.span`
  cursor: pointer;

  & > * {
    & > svg {
      transform: scale(1.6);
      margin-left: 20px;
      fill: ${(props: styledProps) => (props.open ? "white" : props.color)};
    }
  }

  @media screen and (max-width: 666px) {
  }
`

export const NavTitle = (props: Props) => {
  const { isNavOpen, parentProps } = React.useContext(NavBarContext)
  const [styles] = parentProps
  return (
    <StyledTitle {...props} open={isNavOpen} color={styles.color}>
      <Link to="/">
        <Logo />
      </Link>
    </StyledTitle>
  )
}
