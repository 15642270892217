import React from "react"
import styled from "styled-components"

interface Props {
  children: React.ReactNode
}

const StyledFooter = styled.footer`
  display: grid;
  grid-template-columns: 1fr;
  justify-self: flex-end;
  z-index: 2;

  @media screen and (min-width: 1040px) {
    grid-template-columns: 1fr 4fr;
  }
`

const StyledBox = styled.div`
  grid-column: 1;
  background: var(--gradientlight);
  border-radius: 75px 0 0 0;
  box-shadow: -2px -2px 8px rgba(0, 0, 0, 0.3);
  padding: 30px;
  padding-bottom: 0;
  display: grid;
  color: white;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;

  @media screen and (min-width: 760px) {
    grid-column: 2;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, auto);
  }

  @media screen and (min-width: 1040px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const StyledList = styled.ul`
  align-self: center;
  justify-self: center;
  list-style: none;
  grid-row: 2;
  margin: 1rem 0;

  @media screen and (min-width: 760px) {
    grid-column: 4;
    grid-row: 1;
  }

  @media screen and (min-width: 1040px) {
    grid-column: 2;
    grid-row: 1;
  }
`
const StyledListItem = styled.li`
  margin-top: 10px;
  & > a {
    color: inherit;
    font-size: 1.2rem;
    text-decoration: none;
  }
`

const StyledHeading = styled.h4`
  font-size: 1.4rem;
  letter-spacing: 0.4rem;
  text-transform: uppercase;
`
const AddressContainer = styled.div`
  justify-self: center;
  margin: 1rem 0;
`
const StyledAddress = styled.address`
  margin-top: 10px;
  align-self: center;
  justify-self: center;
  line-height: 1.5rem;
`

const REAMCLIMITED = styled.h3`
  align-self: center;
  justify-self: center;
  text-align: center;
  grid-row: 3;

  @media screen and (min-width: 760px) {
    grid-row: 2;
    grid-column: 1 / span 4;
  }

  @media screen and (min-width: 1040px) {
    grid-column: 3;
    grid-row: 1;
  }
`

const BuiltBySeppDev = styled.h5`
  grid-column: 1 / span 3;
  justify-self: center;
  align-self: center;
  padding: 30px;
  text-align: center;

  & a {
    color: var(--secondcolor);
    text-decoration: none;
    text-transform: uppercase;
  }

  @media screen and (min-width: 760px) {
    grid-row: 2;
    grid-column: 1 / span 4;
    font-size: 1.1rem;
  }
`

export const Footer = ({ children }: Props) => {
  return (
    <StyledFooter>
      <StyledBox>
        <AddressContainer>
          <StyledHeading>Address</StyledHeading>
          <StyledAddress>
            REAMC Limited <br />
            4 Nuthatch Close <br />
            Poole <br />
            BH17 7XR <br />
            United Kingdom
          </StyledAddress>
        </AddressContainer>
        <StyledList>
          <StyledHeading>Links</StyledHeading>
          {React.Children.map(children, child => (
            <StyledListItem>{child}</StyledListItem>
          ))}
        </StyledList>
        <REAMCLIMITED>
          REAMC® Limited 2020 - {new Date().getFullYear()} <br /> All Rights
          Reserved
        </REAMCLIMITED>

        <BuiltBySeppDev>
          Website designed and developed by
          <a
            target="_blank"
            rel="noopener referrer"
            href="https://builtbysepp.dev"
          >
            {" "}
            BuiltbySepp.dev
          </a>{" "}
          {new Date().getFullYear()}
        </BuiltBySeppDev>
      </StyledBox>
    </StyledFooter>
  )
}
