import styled from "styled-components"
import { motion } from "framer-motion"

interface StyleProps {
  background: string
  border: string
  altcolor: string
}

export const StyledButton = styled(motion.button)`
  background: ${(props: StyleProps) => props.background};
  border: ${(props: StyleProps) => "3px solid " + props.border};
  width: 180px;
  height: 45px;
  color: ${(props: StyleProps) => props.border};
  border-radius: 5px;
  font-size: calc(8px + 1vmin);
  letter-spacing: 0.3rem;
  cursor: pointer;
  transition: all 300ms var(--bezier);

  &:hover,
  &:focus {
    background: ${(props: StyleProps) => props.border};
    color: ${(props: StyleProps) => props.altcolor};
  }

  @media screen and (max-width: 760px) {
    font-size: 1.2rem;
  }
`
