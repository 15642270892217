import React from "react"
import styled from "styled-components"
import { StyledForm, Form } from "../Form/Form"
import { useOnScreen, useThrottleCallback } from "../../hooks"
import { motion, AnimatePresence } from "framer-motion"

const StyledGrid = styled.div`
  display: grid;
  gap: 50px;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  padding: 40px;
  grid-template-rows: repeat(2, auto);
  padding-bottom: 200px;
`

const StyledHeader = styled(motion.h2)`
  justify-self: center;
  margin-top: 70px;
  color: var(--primary);
  grid-column: 1 / span 2;
  font-size: 2.6rem;
  text-transform: uppercase;

  @media screen and (min-width: 760px) {
    font-size: 4rem;
  }

  @media screen and (min-width: 1040px) {
    justify-self: start;
    width: 60vw;
  }

  @media screen and (min-width: 1550px) {
    width: 50vw;
    font-size: 4rem;
    letter-spacing: 1.2rem;
    justify-self: start;
    align-self: center;
  }
`

const PositionedForm = styled(StyledForm)`
  grid-row: 2;
  grid-column: 1 / span 2;
`

const SentContainer = styled(motion.div)`
  height: 104%;
  width: 100%;
  margin-top: -2%;
  grid-row: 2 / -1;
  grid-column: 1 / -1;
  display: grid;
  place-content: center;
  border: 2px solid #145714;
  background: #b8ebb8;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3), 0 14px 25px rgba(0, 0, 0, 0.14);
  text-align: center;
  z-index: 4;

  & p {
    font-size: 2rem;
    max-width: 10em;
    color: #133a13;
  }
`

interface Props {
  id: string
}

export interface FormFields {
  firstname: string
  surname: string
  company: string
  email: string
  subject: string
  message: string
}

export const Contact = ({ id }: Props) => {
  const [ref, visible] = useOnScreen({ threshold: 0.7 })
  const [formState, setFormState] = React.useState<FormFields>({
    firstname: "",
    surname: "",
    company: "",
    email: "",
    subject: "",
    message: "",
  })

  const [isFormSent, setIsFormSent] = React.useState<boolean>(false)

  const handleChange = e => {
    setFormState({ ...formState, [e.target.id]: e.target.value })
  }

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const submitForm = () => {
    fetch("/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encode({ "form-name": "contact", ...formState }),
    })
      .then(response => {
        if (response.ok) setIsFormSent(true)
      })
      .catch(err => alert(err))
  }

  const throttledSubmit = useThrottleCallback(submitForm, 4000)

  const handleSubmit = (e: Event) => {
    e.preventDefault()
    throttledSubmit()
  }

  return (
    <StyledGrid id={id}>
      <StyledHeader
        ref={ref}
        variant={{
          hidden: { x: -50, opacity: 0 },
          visible: { x: 0, opacity: 1 },
        }}
        initial="hidden"
        animate={visible ? "visible" : "hidden"}
        exit={{ x: -2000, opacity: 0 }}
      >
        Contact
      </StyledHeader>
      <PositionedForm
        name="contact"
        method="post"
        data-netlify="true"
        netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
        initial={{ x: 0, opacity: 1 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 30, opacity: 0 }}
      >
        <input type="hidden" name="contact" value="hidden field" />
        <Form handleChange={handleChange} />
      </PositionedForm>
      <AnimatePresence>
        {isFormSent && (
          <SentContainer
            initial={{ rotateX: 60, opacity: 0 }}
            animate={{ rotateX: 0, opacity: 1 }}
            transition={{
              type: "spring",
              bounce: 0.1,
              damping: 50,
              mass: 10,
            }}
          >
            <p>
              Thank you for your message!
              <br />
              <small style={{ fontSize: ".6em" }}>
                we will be in contact with you as soon as possible
              </small>
            </p>
          </SentContainer>
        )}
      </AnimatePresence>
    </StyledGrid>
  )
}
