import * as React from "react"

export const useOnScreen = options => {
  const [visible, setVisible] = React.useState(false)
  const [ref, setRef] = React.useState(null)

  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting)
    }, options)

    if (ref) {
      observer.observe(ref)
    }

    if (visible) {
      observer.unobserve(ref)
    }

    return () => {
      if (ref) {
        observer.unobserve(ref)
      }
    }
  }, [ref, options, visible])

  return [setRef, visible, ref]
}
