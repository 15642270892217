import * as React from "react"
import NavBarContext from "./context"
import styled from "styled-components"
import { Link, navigate } from "gatsby"

import { StyledButton } from "../Button"
import scrollTo from "gatsby-plugin-smoothscroll"

interface Props {
  path: string
  router?: boolean
  btn?: boolean
  className?: string
  onClick?: (string) => boolean
  children: React.ReactNode
}

interface styledProps {
  color: string
}

const defaultProps = {
  path: "/",
  router: false,
}

const StyledNavItem = styled.li`
  font-size: 18px;
  margin-bottom: 30px;

  a {
    display: flex;
    color: ${(props: styledProps) => props.color};
    text-decoration: none;
  }

  & i {
    color: lightgrey;
  }

  @media screen and (min-width: 760px) {
    font-size: 14px;
    margin: 0 1vw;
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    position: relative;
    padding: 0 1vw;
    font-size: 18px;

    &:last-of-type {
      color: red;
    }

    &::after {
      content: "";
      position: absolute;
      width: 80%;
      height: 2px;
      bottom: 0;
      left: 10%;

      background: ${(props: styledProps) => props.color};
      transform-origin: left;
      transform: scaleX(0);
      transition: transform 200ms cubic-bezier(0.51, 0.92, 0.24, 1);
    }

    &:hover::after {
      transform: scaleX(1);
    }

    & a {
      display: flex;
      color: ${(props: styledProps) => props.color};
      text-decoration: none;
    }

    & i {
      color: lightgrey;
    }
  }
`

const NavButton = styled(StyledButton)`
  color: white;
  border: 2px solid white;
  align-self: flex-start;
  justify-self: center;
  margin-left: auto;
  margin-top: 20vh;

  & a {
    color: white;
    text-decoration: none;
  }

  &:hover {
    background: transparent;
  }

  @media screen and (min-width: 760px) {
    display: none;
  }
`

export const NavListItem = ({
  children,
  path,
  router,
  btn,
  onClick,
  ...props
}: Props) => {
  const { isNavOpen, setNavState, parentProps } = React.useContext(
    NavBarContext
  )
  const [styles] = parentProps
  const clickHandler = (): void => {
    setNavState(false)
  }

  return !btn ? (
    <StyledNavItem color={isNavOpen ? "white" : styles.color}>
      {!router ? (
        <a {...props} onClick={clickHandler} href={path}>
          {children}
        </a>
      ) : (
        <Link
          {...props}
          onClick={() => {
            clickHandler()
            window.location.pathname !== "/"
              ? navigate(path)
              : path === "/"
              ? scrollTo("#hero")
              : scrollTo(path.slice(1))
          }}
          to={path}
        >
          {children}
        </Link>
      )}
    </StyledNavItem>
  ) : (
    <NavButton
      background="transparent"
      border="var(--primary)"
      color="var(--primary)"
      altcolor="#fff"
      onClick={() => {
        clickHandler()
        window.location.pathname !== "/"
          ? navigate("/#contact")
          : scrollTo("#contact")
      }}
    >
      {children}
    </NavButton>
  )
}
NavListItem.defaultProps = defaultProps
