import * as React from "react"
import styled from "styled-components"
import Img from "../../assets/images/pumpjack2.jpg"
import { StyledButton } from "../Button"
import { Grid } from "../Grid"
import { useOnScreen, useWindowSize } from "../../hooks"
import { motion } from "framer-motion"
import scrollTo from "gatsby-plugin-smoothscroll"

const StyledGrid = styled(Grid)`
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  padding: 50px;
  perspective: 1500px;

  &::before {
    content: "";
    position: absolute;
    background: var(--gradientlight);
    width: 100vw;
    height: 750px;
    border-radius: 0 0 70px 0;
    top: -20px;
    left: 0;
    z-index: -1;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5);
  }

  @media screen and (min-width: 760px) {
    padding: 20px;
    margin-bottom: 340px;
    height: 800px;
  }

  @media screen and (min-width: 1550px) {
    margin-bottom: 100px;
  }

  @media screen and (min-width: 1550px) {
    grid-template-columns: 8vw repeat(4, 1fr) 50%;

    &::before {
      width: 80%;
      height: 850px;
      border-radius: 0 0 45px 0;
    }
  }
`

const StyledHeader = styled(motion.h1)`
  color: white;
  grid-row: 1;
  font-style: italic;
  font-size: 2.6rem;
  letter-spacing: 0.3rem;
  align-self: end;
  justify-self: start;
  max-width: 5em;

  @media screen and (min-width: 760px) {
    justify-self: center;
    font-size: 4rem;
    letter-spacing: 0.5rem;
    max-width: 10em;
  }

  @media screen and (min-width: 1550px) {
    align-self: center;
    justify-self: start;
    font-size: 6rem;
    letter-spacing: 0.7rem;
    font-weight: 600;

    grid-column: 2 / span 5;
    grid-row: 2;
  }

  @media screen and (min-width: 2000px) {
    font-size: clamp(2rem, 5vw, 7rem);
  }
`

const StyledParagraph = styled(motion.p)`
  color: var(--secondary);
  grid-row: 2;
  align-self: center;
  justify-self: start;
  font-size: 1.4rem;
  min-width: 300px;
  max-width: 20em;

  @media screen and (min-width: 760px) {
    align-self: center;
    justify-self: center;
  }

  @media screen and (min-width: 1550px) {
    justify-self: start;
    align-self: start;
    font-size: 1.7rem;
    letter-spacing: 0.2rem;
    line-height: 2.4rem;
    grid-column: 2 / span 3;
    grid-row: 3;
  }
`

const StyledImage = styled(motion.img)`
  object-fit: cover;
  width: 90%;
  max-width: 350px;
  grid-row: 4;
  grid-column: 1;
  justify-self: center;
  align-self: end;
  position: relative;
  border-radius: 75px 0 75px 0;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
  margin-top: -50px;
  margin-left: -10px;

  min-width: 240px;

  @media screen and (min-width: 760px) {
    display: block;
    width: 70%;
    height: 40vh;
    max-width: 600px;
    max-height: 375px;
    min-height: 350px;
    border-radius: 75px 0 75px 0;

    margin-top: 0;
    grid-column: 1;
    grid-row: 4;
    justify-self: center;
    align-self: flex-start;
  }

  @media screen and (min-width: 1550px) {
    grid-column: 6;
    grid-row: 3;
    width: 570px;
    height: 500px;
    max-height: 450px;
  }
`

const HeroButton = styled(StyledButton)`
  grid-row: 3;
  align-self: start;
  justify-self: start;

  @media screen and (min-width: 760px) {
    grid-row: 3;
    justify-self: center;
    align-self: start;
  }

  @media screen and (min-width: 1550px) {
    grid-column: 2;
    grid-row: 4;
    justify-self: start;
    align-self: start;
  }
`

export const Hero = () => {
  const [headerref, headervisible] = useOnScreen({ threshold: 0.4 })

  const { width } = useWindowSize()
  const [rotation, setRotation] = React.useState({
    y: width <= 740 ? -20 : -38,
    z: width <= 740 ? 5 : 5,
  })

  React.useEffect(() => {
    setRotation({
      y: width <= 740 ? -20 : -38,
      z: width <= 740 ? 5 : 5,
    })
  }, [width])

  const Variants = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -30 },
    threedimHidden: {
      opacity: 0,
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,
    },
    threedimVisible: {
      opacity: 1,
      rotateX: 15,
      rotateY: rotation.y, //28
      rotateZ: rotation.z, //5
    },
  }

  const btnVariants = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -30 },
  }

  return (
    <StyledGrid
      columns="100px repeat(4, 1fr) 50%"
      rows="repeat(4,200px)"
      gap="10px"
      id="hero"
      initial={{ scale: 0 }}
      animate={{
        scale: 1,
      }}
      transition={{
        type: "spring",
      }}
    >
      <StyledHeader
        ref={headerref}
        variants={Variants}
        initial="hidden"
        animate={headervisible ? "visible" : "hidden"}
        transition={{
          type: "tween",
          duration: 1,
        }}
      >
        REAMC Limited
      </StyledHeader>

      <StyledParagraph
        variants={Variants}
        initial="hidden"
        animate={headervisible ? "visible" : "hidden"}
        transition={{
          type: "tween",
          duration: 1,
          delay: 0.2,
        }}
      >
        An Independent Reservoir Engineering and Applied Mathematics Consultancy
        Company
      </StyledParagraph>

      <StyledImage
        variants={Variants}
        initial="threedimHidden"
        animate={headervisible ? "threedimVisible" : "threedimHidden"}
        transition={{
          type: "tween",
          delay: 0.4,
          duration: 2,
        }}
        src={Img}
        alt="beach"
      />

      <HeroButton
        onClick={() => scrollTo("#contact")}
        variants={btnVariants}
        initial="hidden"
        animate={headervisible ? "visible" : "hidden"}
        transition={{
          type: "tween",
          duration: 1,
          delay: 0.3,
        }}
        border="white"
        background="transparent"
        altcolor="var(--primary)"
      >
        Contact Us
      </HeroButton>
    </StyledGrid>
  )
}
