import * as React from "react"

export const useWindowScroll = (): number => {
  const [scrollPos, setScrollPos] = React.useState(0)
  const isClient: boolean = typeof window === "object"

  const getScrollPosition = React.useCallback((): number | undefined => {

    return isClient ? window.scrollY : 0
  }, [isClient])

  const handleScroll = React.useCallback(() => {
    const pos: number = getScrollPosition()
    setScrollPos(pos)
  }, [getScrollPosition])


  React.useEffect(() => {
    setScrollPos(window.scrollY)
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return scrollPos
}
