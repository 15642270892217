import * as React from "react"
import styled from "styled-components"
import NavBarContext from "./context"
import { useNavContext, useWindowScroll } from "./hooks"

type Props = {
  fixed?: boolean
  background?: string
  color?: string
  scrollColor?: string
  secondary?: string
  className?: string
  children: React.ReactNode
}

interface styledProps {
  className: string
  styles: CSSStyleDeclaration
  fixed: boolean
  scrolled: boolean
  open: boolean
  secondary: string
  background: string
  color: string
  padding: string
  scrollColor: string
}

const defaultProps = {
  fixed: false,
  background: "#007acc",
  color: "white",
}

const StyledNav = styled.nav`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;

  position: ${(props: styledProps) => (props.fixed ? "fixed" : "auto")};
  z-index: ${(props: styledProps) => (props.fixed ? 100000000 : 1)};

  padding: ${(props: styledProps) =>
    props.scrolled ? (props.open ? "20px 20px" : "2px 30px") : "20px 30px"};
  width: 100vw;

  background: ${(props: styledProps) =>
    props.open
      ? "var(--gradientlight)"
      : props.scrolled
      ? "#fff"
      : "transparent"};

  box-shadow: ${(props: styledProps) =>
    props.scrolled ? "4px 4px 7px rgba(0, 0, 0, 0.4)" : "0"};
  transition: background 100ms ease, box-shadow 200ms ease,
    padding 200ms ease 50ms;

  font-size: 2rem;

  @media screen and (max-width: 720px) {
    justify-content: space-between;
    box-shadow: ${(props: styledProps) =>
      props.scrolled
        ? "4px 4px 7px rgba(0, 0, 0, 0.4)"
        : props.open
        ? "4px 4px 7px rgba(0, 0, 0, 0.4)"
        : "0"};

    padding: ${(props: styledProps) =>
      props.scrolled ? (props.open ? "20px 20px" : "10px 10px") : "20px 30px"};
  }
`

const Shadow = styled.div`
  display: flex;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000000;
  top: 0;
  right: 0;
  left: 0;

  @media screen and (min-width: 760px) {
    display: none;
  }
`

/**
 * COMPONENT
 */

export const Navbar = ({
  children,
  background,
  secondary,
  color,
  scrollColor,
  ...props
}: Props): JSX.Element => {
  const { Provider } = NavBarContext
  const scrollPos = useWindowScroll()
  color = scrollPos ? "#515396" : color
  const ctx = useNavContext([{ color }])

  React.useLayoutEffect(() => {
    const html = document.querySelector("html")
    const body = document.querySelector("body")

    if (ctx.isNavOpen) {
      body.classList.add("stopscrolling")
      html.classList.add("stopscrolling")
    } else {
      body.classList.remove("stopscrolling")
      html.classList.remove("stopscrolling")
    }
  }, [ctx])

  const handleClick = (): void => {
    ctx.setNavState(false)
  }

  return (
    <Provider value={ctx}>
      <StyledNav
        open={ctx.isNavOpen}
        scrolled={!!scrollPos}
        background={!!scrollPos}
        secondary={secondary}
        {...props}
      >
        {children}
      </StyledNav>
      {ctx.isNavOpen && <Shadow onClick={handleClick} />}
    </Provider>
  )
}

Navbar.defaultProps = defaultProps
