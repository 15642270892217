import * as React from "react"
import styled from "styled-components"

interface StyledProps {
  bg: string

  p?: string
  px?: string
  py?: string
  pt?: string
  pb?: string
  pl?: string
  pr?: string
}

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: ${(props: StyledProps) => {
    if (props.hasOwnProperty("p")) {
      return props.p
    } else {
      "0"
    }
  }};
  padding-top: ${(props: StyledProps) => (props.pt ? props.pt : null)};
  padding-left: ${(props: StyledProps) => (props.pl ? props.pl : null)};
  padding-bottom: ${(props: StyledProps) => (props.pb ? props.pb : null)};
  padding-right: ${(props: StyledProps) => (props.pr ? props.pr : null)};
  background: ${(props: StyledProps) => props.bg};
`

interface Props {
  bg: string

  p: string
  px?: string
  py?: string
  pt?: string
  pb?: string
  pl?: string
  pr?: string

  children: React.ReactNode
}

const defaultProps = {
  bg: "white",
}

export const Section = ({ children, ...props }: Props) => {
  return <StyledSection {...props}>{children}</StyledSection>
}

Section.defaultProps = defaultProps
