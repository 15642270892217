import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"

const StyledProjectNavigation = styled.div`
  z-index: 12;
  color: #bbb;
  padding-left: 40px;
  font-size: 2rem;
  display: flex;
  align-items: flex-end;
`

interface MenuProps {
  active: boolean
}

const StyledSectionMenu = styled(motion.h3)`
  margin-right: -20px;
  cursor: pointer;
  min-width: 80px;
  color: ${(props: MenuProps) => (props.active ? "white" : "#bbb")};
  transition: color 300ms ease;
`

interface Props {
  isActive: number
  setActive: React.Dispatch<any>
}

export const ProjectNav = ({ isActive, setActive }: Props) => {
  const handleClick = clickedVal => {
    setActive(clickedVal)
    sessionStorage.setItem("reamc:previousContainer", clickedVal)
  }

  const [isClient, setClient] = React.useState<boolean>(false)

  React.useEffect(() => {
    setClient(true)
  }, [])

  const key = isClient ? `client` : `server`

  return (
    <StyledProjectNavigation key={key}>
      <div style={{ display: "flex" }}>
        <StyledSectionMenu
          active={isActive === 0 ? true : false}
          onClick={() => {
            handleClick(0)
          }}
          animate={{ scale: isActive === 0 ? 1 : 0.7 }}
        >
          Reservoir Engineering
        </StyledSectionMenu>
        <StyledSectionMenu
          active={isActive === 1 ? true : false}
          onClick={() => {
            handleClick(1)
          }}
          animate={{ scale: isActive === 1 ? 1 : 0.7 }}
        >
          Applied Mathematics
        </StyledSectionMenu>
      </div>
    </StyledProjectNavigation>
  )
}
