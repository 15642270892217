import React from "react"
import {
  StyledCard,
  StyledPicture,
  StyledText,
  FlexedPictureBox,
} from "./Styles/AboutStyles"

import Img from "gatsby-image/withIEPolyfill"
import { graphql, useStaticQuery } from "gatsby"

export const AboutCard = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "assets/images/RSW_Profile.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)


  return (
    <StyledCard>
      <FlexedPictureBox>
        <StyledPicture>
          <Img
            objectFit="cover"
            fadeIn
            fluid={data.file.childImageSharp.fluid}
          />
        </StyledPicture>
        <p>
          Director, Reservoir Engineering Advisor <br /> Roy Wikramaratna
        </p>
      </FlexedPictureBox>
      <StyledText>
        In 2016 Roy Wikramaratna set up REAMC Limited as an independent
        reservoir engineering and applied mathematics consultancy company,
        working primarily in the upstream oil and gas industry. Roy has some 40
        years’ industry experience in porous medium flow modelling, applied to
        oil reservoir engineering, CO2 sequestration, enhanced oil recovery and
        groundwater resource evaluation.
      </StyledText>
      <StyledText>
        He has successfully managed and undertaken Reserves audits, complex
        engineering evaluations, research and software development projects, and
        has provided high-level technical consultancy both within the industry
        and for the UK Government (eg DECC and its predecessor Departments). He
        has experience of managing and working in integrated teams covering all
        areas of upstream oilfield development.
      </StyledText>
      <StyledText>
        Throughout his career, Roy has developed and maintained a research
        interest both in the theoretical development and in the practical
        application of numerical methods to solve real world problems, relating
        to oil reservoir engineering and radioactive waste management.
      </StyledText>
    </StyledCard>
  )
}
