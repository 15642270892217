import React from "react"
import {
  StyledGrid,
  StyledMainHeader,
  StyledSectionContainer,
} from "../../../styles/pageStyles"
import { MobileNav, NavMenuItem } from "./MobileNav"
import { StyledCardContainer } from "../Desktop/DesktopProjectsView"
import { MobileProjectList } from "./MobileProjectList"
import { MobileModel } from "./MobileModel"
import styled from "styled-components"

const ProjectsGrid = styled(StyledGrid)`
  margin-bottom: 450px;
`

const StyledMobileSectionContainer = styled(StyledSectionContainer)`
  display: flex;
  width: 200vw;
  gap: 8vw;
`
const StyledMobileCard = styled(StyledCardContainer)`
  position: relative;
`

const NavMainHeader = styled(StyledMainHeader)`
  justify-self: start;
  padding-left: 1.3rem;
`

const StyledBodyText = styled.p`
  margin: 1.3rem;
`
const StyledWrapper = styled.div`
  /* max-height: 500px;
  overflow-y: scroll; */
  border-radius: 0 0 0 20px;
  /* border: 4px solid var(--secondcolor); */
`

interface props {
  state: string
}

export const MobileProjectsView = ({ state }: props) => {
  const [REisActive, REsetIsActive] = React.useState<false | number>(false)
  const [AMisActive, AMsetIsActive] = React.useState<false | number>(false)
  const [isREModalOpen, setIsREModelOpen] = React.useState<boolean>(false)
  const [isAMModalOpen, setIsAMModelOpen] = React.useState<boolean>(false)
  const [activeContainer, setActiveContainer] = React.useState<number>(
    state === "Maths" ? 1 : 0
  )

  const [REcontent, setREContent] = React.useState({
    title: "",
    company: "",
    location: "",
    body: "",
  })

  const [AMcontent, setAMContent] = React.useState({
    title: "",
    company: "",
    location: "",
    body: "",
  })

  React.useEffect(() => {
    REcontent.company !== "" && setIsREModelOpen(true)
  }, [REcontent])

  React.useEffect(() => {
    AMcontent.company !== "" && setIsAMModelOpen(true)
  }, [AMcontent])

  React.useEffect(() => {
    const container = sessionStorage.getItem("reamc:previousContainer")

    if (state) {
      setActiveContainer(state === "Maths" ? 1 : 0)
    } else if (container) {
      setActiveContainer(parseInt(container))
    }
  }, [])

  return (
    <ProjectsGrid>
      <NavMainHeader>Projects</NavMainHeader>
      <MobileModel
        type="RE"
        content={REcontent}
        isVisible={isREModalOpen}
        setVisible={setIsREModelOpen}
      />
      <MobileModel
        type="AM"
        content={AMcontent}
        isVisible={isAMModalOpen}
        setVisible={setIsAMModelOpen}
      />

      <StyledMobileSectionContainer
        animate={{ x: activeContainer === 0 ? 0 : "-51.5%" }}
        transition={{
          type: "tween",
          duration: 0.8,
        }}
      >
        <StyledMobileCard
          animate={{ opacity: activeContainer === 0 ? 1 : 0 }}
          transition={{
            type: "tween",
            duration: 0.8,
          }}
        >
          <StyledBodyText>
            REAMC Limited is able to undertake work contracted directly with Oil
            & Gas Companies; additionally, we have Associate agreements with a
            number of other sub-surface consultancy companies with whom we may
            undertake joint projects. <br />
            <br /> Past projects have included Due Diligence / Competent Persons
            Reports in support of M&A activity, FDP review on behalf of
            Government regulator, Peer Review / Technical Advice concerning
            complex reservoir simulation models and acting as an Expert Witness
            on Reservoir Engineering issues.
          </StyledBodyText>
          <StyledWrapper>
            <MobileProjectList
              list={"RE"}
              setContent={setREContent}
              isActive={REisActive}
              setIsActive={REsetIsActive}
            />
          </StyledWrapper>
        </StyledMobileCard>
        <StyledMobileCard
          animate={{ opacity: activeContainer === 1 ? 1 : 0 }}
          transition={{
            type: "tween",
            duration: 0.8,
          }}
        >
          <StyledBodyText>
            The main focus of our R&D work is related to the underlying
            mathematics of pseudo-random number generation methods, including
            both theoretical analysis and empirical testing; also applications
            including stochastic modelling methods and cryptography.
          </StyledBodyText>
          <StyledWrapper>
            <MobileProjectList
              list={"AM"}
              setContent={setAMContent}
              isActive={AMisActive}
              setIsActive={AMsetIsActive}
            />
          </StyledWrapper>
        </StyledMobileCard>
      </StyledMobileSectionContainer>

      <MobileNav>
        <NavMenuItem
          onClick={() => activeContainer !== 0 && setActiveContainer(0)}
          active={activeContainer === 0}
        >
          Reservoir Engineering
        </NavMenuItem>
        <NavMenuItem
          onClick={() => activeContainer !== 1 && setActiveContainer(1)}
          active={activeContainer === 1}
        >
          Applied Mathematics
        </NavMenuItem>
      </MobileNav>
    </ProjectsGrid>
  )
}
