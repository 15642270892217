import * as React from "react"
import { INavBarContext } from "../context"

/**
 * useNavContext Hook
 */

export const useNavContext = (passedProps: any[] = []): INavBarContext => {
  const [state, setState] = React.useState(false)

  const setNavState = React.useCallback(
    (value: boolean): void => {
      !state
        ? (document.body.style.overflow = "hidden")
        : (document.body.style.overflowY = "auto")
      setState(value)
    },
    [state]
  )

  return {
    isNavOpen: state,
    setNavState,
    parentProps: passedProps,
  }
}
