import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { ProjectListItem } from "./ProjectListItem"

const StyledMenuContainer = styled.div`
  @media screen and (max-width: 760px) {
    border: 8px solid var(--secondcolor);
    height: 70vh;
    background: rgba(255, 255, 255);
  }

  grid-row: 2;
  padding: 10px;

  height: 520px;
  border: 4px solid var(--secondcolor);

  overflow-y: scroll;
`

interface MenuWrapperProps {
  bottomShadowVisible: boolean
  topShadowVisible: boolean
}

const StyledMenuWrapper = styled.div`
  @media screen and (max-width: 760px) {
    margin: 0;
  }

  position: relative;
  margin: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

  /* &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 7%;
    background: linear-gradient(
      rgba(0, 0, 0, 0.24),
      rgba(0, 0, 0, 0.07),
      rgba(0, 0, 0, 0)
    );
    top: 0;
    z-index: 10;
    opacity: ${(props: MenuWrapperProps) => (props.topShadowVisible ? 1 : 0)};
    transition: opacity 500ms ease;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 7%;
    background: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.07),
      rgba(0, 0, 0, 0.24)
    );
    z-index: 10;
    bottom: 0;
    opacity: ${(props: MenuWrapperProps) =>
      props.bottomShadowVisible ? 1 : 0};
    transition: opacity 500ms ease;
  } */
`

export const ProjectList = ({ list, setContent, isActive, setIsActive }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { type: { regex: "/AM|RE/" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
              project
              company
              location
              type
              key
            }
            id
            html
            rawMarkdownBody
          }
        }
      }
    }
  `)

  // const scrollContainerRef = React.useRef<HTMLElement>()
  // const [scroll, setScroll] = React.useState<number>()
  // const [maxScroll, setMaxScroll] = React.useState<number>()
  // const [shadowVisiblity, setShadowVisiblity] = React.useState({
  //   top: scroll !== 0,
  //   bottom: scroll <= maxScroll,
  // })

  // React.useLayoutEffect(() => {
  //   scrollContainerRef.current.addEventListener("scroll", (e: any) => {
  //     setScroll(scrollContainerRef.current.scrollTop)
  //     setMaxScroll(
  //       scrollContainerRef.current.scrollHeight -
  //         scrollContainerRef.current.offsetHeight
  //     )
  //   })
  // })

  // React.useEffect(() => {
  //   if (scrollContainerRef.current.scrollHeight <= 500) {
  //     setShadowVisiblity({
  //       top: false,
  //       bottom: false,
  //     })
  //   } else {
  //     setShadowVisiblity({
  //       top: scroll !== 0,
  //       bottom: scroll <= maxScroll,
  //     })
  //   }
  // }, [scroll])

  return (
    <StyledMenuWrapper
    // bottomShadowVisible={shadowVisiblity.bottom}
    // topShadowVisible={shadowVisiblity.top}
    >
      <StyledMenuContainer
      // ref={scrollContainerRef}
      >
        {data.allMarkdownRemark.edges
          .filter((edge: any) => edge.node.frontmatter.type === list)
          .sort(
            (a: any, b: any) => a.node.frontmatter.key - b.node.frontmatter.key
          )
          .map(edge => (
            <ProjectListItem
              id={edge.node.id}
              preload={false}
              key={edge.node.id}

              title={edge.node.frontmatter.title}
              company={edge.node.frontmatter.company}

              location={
                list === "RE"
                  ? edge.node.frontmatter.location
                  : edge.node.frontmatter.project
              }
              body={edge.node.html}
              onClick={setContent}
              isActive={isActive}
              setIsActive={setIsActive}
            >
              {edge.node.frontmatter.title}
            </ProjectListItem>
          ))}
      </StyledMenuContainer>
    </StyledMenuWrapper>
  )
}
