import Brand from "./svg/Logo.svg"
import Menu from "./svg/Menu.svg"
import Arrow from "./svg/Arrow.svg"
import Cancel from "./svg/Cancel.svg"
import Logo from "./svg/Logo.svg"

export { Brand }
export { Menu }
export { Arrow }
export { Cancel }
export { Logo }
