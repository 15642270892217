import { motion } from "framer-motion"
import styled from "styled-components"

export const StyledGrid = styled.main`
  display: grid;
  min-height: 100vh;
  padding-top: 70px;
  position: relative;
  grid-template-columns: 1fr;
  grid-template-rows: 70px repeat(2, auto);
  margin-bottom: 100px;

  &::before {
    content: "";
    position: absolute;
    background: var(--gradientlight);
    width: 100vw;
    height: 700px;
    border-radius: 0 0 70px 0;
    top: 0;
    left: 0;
    z-index: 0;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5);
  }

  @media screen and (min-width: 760px) {
  }
  @media screen and (min-width: 1300px) {
    grid-template-rows: 150px 75px repeat(2, auto);
  }

  @media screen and (min-width: 1550px) {
    padding-top: 70px;
    &::before {
      width: 80%;
      height: 900px;
      border-radius: 0 0 45px 0;
    }
  }
`

export const StyledMainHeader = styled.h2`
  z-index: 1;
  color: white;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-style: italic;
  align-self: center;
  justify-self: center;
  text-align: center;

  @media screen and (min-width: 760px) {
    font-size: 2.5rem;
    margin-top: 20px;
  }
  @media screen and (min-width: 1040px) {
    font-size: 3rem;
    justify-self: flex-start;
    margin-left: 10%;
  }
  @media screen and (min-width: 1550px) {
  }
`

export const StyledSectionContainer = styled(motion.div)`
  z-index: 1;
  justify-self: start;
  padding: 5px;
  width: 100%;

  position: relative;

  @media screen and (min-width: 760px) {
    padding: 10px;
  }
  @media screen and (min-width: 1040px) {
    padding: 20px;
  }
  @media screen and (min-width: 1550px) {
    padding: 30px;
    padding-top: 0;
  }
`
