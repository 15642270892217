import * as React from "react"

export const useWindowSize = () => {
  const isClient = typeof window === "object"
  const getSize = React.useCallback(() => {
    return {
      width: isClient ? window.innerWidth : undefined,

      height: isClient ? window.innerHeight : undefined,
    }
  }, [isClient])

  const [windowSize, setWindowSize] = React.useState(getSize)

  React.useEffect(() => {
    if (!isClient) {
      return
    }

    function handleResize() {
      const size = getSize()
      setWindowSize(size)
    }

    window.addEventListener("resize", handleResize)

    return () => window.removeEventListener("resize", handleResize)
  }, [getSize, isClient, windowSize])

  return windowSize
}
