import * as React from 'react';

/**
 * NAVBAR CONTEXT
 *
 */

const INITIAL_NAV_CONTEXT = {
  setNavState: () => {},
  isNavOpen: false,
  parentProps: [],
};

export interface INavBarContext {
  setNavState: (value: boolean) => void;
  isNavOpen: boolean;
  parentProps: any[];
}

export const NavBarContext = React.createContext<INavBarContext>(
  INITIAL_NAV_CONTEXT
);
