import * as React from "react"
import NavBarContext from "./context"
import styled from "styled-components"
type Props = {
  background: string
  className?: string
  children: React.ReactNode
}

interface StyleProps {
  open: boolean
  background: string
}

const StyledList = styled.ul`
  display: flex;
  position: relative;
  list-style: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  flex-grow: 3;
  gap: calc(15px - .5vw);

  @media screen and (max-width: 760px) {
    flex-direction: column;
    position: fixed;
    height: 100vh;
    background: ${(props: StyleProps) => props.background};
    right: 0;
    top: 70px;
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 10vw;
    justify-content: flex-start;
    margin-right: 0px;
    align-items: flex-start;
    transform: ${(props: StyleProps) =>
      props.open ? "none" : "translateX(110%)"};
    box-shadow: -4px 2px 7px rgba(0, 0, 0, 0.4);
    transition: transform 300ms cubic-bezier(0.51, 0.92, 0.24, 1);
  }
`

export const NavList = ({ children, background, ...props }: Props) => {
  const { isNavOpen, parentProps } = React.useContext(NavBarContext)
  const [styles] = parentProps

  return (
    <>
      <StyledList
        {...props}
        color={styles.color}
        background={background}
        open={isNavOpen}
      >
        {children}
      </StyledList>
    </>
  )
}
