import React from "react"

/**

 * @description throttles a provided callback function by x milliseconds, callback will not be invokes untill provided time value has expired
 *
 * @param callback : callback to throttle
 * @param value: time in milliseconds you wish tottle the callback for.
 *
 * @returns throttled callback function
 */

export function useThrottleCallback(callback: () => any, value: number) {
  const [isCallbackAvailable, setIsCallbackAvailable] = React.useState(true)

  const c = React.useCallback(() => {
    if (!isCallbackAvailable) return
    setIsCallbackAvailable(false)
    setTimeout(() => setIsCallbackAvailable(true), value)
    callback()
  }, [callback, value])

  return c
}
