import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const Table = styled.table`
  background: white;
  max-width: 1800px;
  border: 4px solid var(--secondcolor);
  border-spacing: 0;
  border-radius: 4px 30px 4px 30px;
  margin-bottom: 50px;
  padding-left: 20px;
`
const TableBody = styled.tbody`
  padding: 5px;

  & tr {
    height: 5px;
  }
`
const TableHead = styled.thead`
  & tr {
    height: 50px;
  }
`
const TableHeading = styled.th`
  padding: 5px 10px 5px 0px;
  text-align: left;
  text-transform: capitalize;
`
const TableRow = styled.tr`
  & td::first-of-type {
    padding-left: 50px;
  }
`
const TableData = styled.td`
  font-size: 0.9rem;
  max-width: 20rem;
  padding-right: 10px;
  padding-bottom: 20px;
  vertical-align: center;
  height: 50px;
`

interface Props {
  data: any[]
  heading: string
}

export const PublicationTable = ({ data, heading }: Props) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeading>{heading}</TableHeading>
          <TableHeading>title</TableHeading>
          <TableHeading>date</TableHeading>
          <TableHeading>author</TableHeading>
          <TableHeading>Abstract</TableHeading>
          <TableHeading>Download</TableHeading>
        </TableRow>
      </TableHead>
      <TableBody>
        {data
          .sort(
            (a: any, b: any) => b.node.frontmatter.key - a.node.frontmatter.key
          )
          .map((edge, i) => (
            <TableRow key={i}>
              <TableData>
                {heading === "Report"
                  ? edge.node.frontmatter.report
                  : edge.node.frontmatter.location}
              </TableData>
              <TableData>{edge.node.frontmatter.title}</TableData>
              <TableData>{edge.node.frontmatter.date}</TableData>
              <TableData>{edge.node.frontmatter.author}</TableData>
              <TableData>
                <Link
                  className="btn"
                  to={`/Publications/${edge.node.fields.slug}`}
                  state={{ previous: "/Publications" }}
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    height="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                    ></path>
                  </svg>
                  Abstract
                </Link>
              </TableData>
              <TableData>
                <a
                  className="btn"
                  download
                  href={`${edge.node.frontmatter.download}`}
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    height="20"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    ></path>
                  </svg>
                  Download
                </a>
              </TableData>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  )
}
