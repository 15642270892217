import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import { ProjectListItem } from "../Desktop/ProjectListItem"

const StyledMenuContainer = styled.div`
  @media screen and (max-width: 760px) {
    background: rgba(255, 255, 255);
    border: none;
    border-radius: 0 0 0 20px;
  }

  grid-row: 2;
  padding: 10px;

  /* border: 4px solid var(--secondcolor); */

`

export const MobileProjectList = ({
  list,
  setContent,
  isActive,
  setIsActive,
}) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { type: { regex: "/AM|RE/" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
              project
              company
              location
              type
              key
            }
            id
            html
            rawMarkdownBody
          }
        }
      }
    }
  `)

  // const scrollContainerRef = React.useRef<HTMLElement>()
  // const [scroll, setScroll] = React.useState<number>()
  // const [maxScroll, setMaxScroll] = React.useState<number>()
  // const [shadowVisiblity, setShadowVisiblity] = React.useState({
  //   top: scroll !== 0,
  //   bottom: scroll <= maxScroll,
  // })

  // React.useLayoutEffect(() => {
  //   scrollContainerRef.current.addEventListener("scroll", (e: any) => {
  //     setScroll(scrollContainerRef.current.scrollTop)
  //     setMaxScroll(
  //       scrollContainerRef.current.scrollHeight -
  //         scrollContainerRef.current.offsetHeight
  //     )
  //   })
  // })

  // React.useEffect(() => {
  //   if (scrollContainerRef.current.scrollHeight <= 500) {
  //     setShadowVisiblity({
  //       top: false,
  //       bottom: false,
  //     })
  //   } else {
  //     setShadowVisiblity({
  //       top: scroll !== 0,
  //       bottom: scroll <= maxScroll,
  //     })
  //   }
  // }, [scroll])

  return (
    <StyledMenuContainer
    // ref={scrollContainerRef}
    >
      {data.allMarkdownRemark.edges
        .filter((edge: any) => edge.node.frontmatter.type === list)
        .sort(
          (a: any, b: any) => a.node.frontmatter.key - b.node.frontmatter.key
        )
        .map((edge: any) => (
          <ProjectListItem

            title={edge.node.frontmatter.title}
            id={edge.node.id}
            key={edge.node.id}
            preload={false}
            company={edge.node.frontmatter.company}

            location={
              edge.node.frontmatter.location || edge.node.frontmatter.project
            }
            body={edge.node.html}
            onClick={setContent}
            isActive={isActive}
            setIsActive={setIsActive}
          >
            {edge.node.frontmatter.title}
          </ProjectListItem>
        ))}
    </StyledMenuContainer>
  )
}
