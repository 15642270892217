import { motion, useAnimation } from "framer-motion"
import React from "react"
import styled from "styled-components"


interface StyleProps {
  isRE: boolean
}

const StyledItemContent = styled.div`
  grid-column: 2;
  grid-row: 1 / span 2;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
  margin: 20px;
  border: 4px solid var(--secondcolor);
  padding: 30px;
  height: 670px;
  display: grid;
  grid-template-columns: 1fr;

  grid-template-rows: ${(props: StyleProps) =>
    props.isRE ? "auto 40px 100px 1fr" : "auto 100px 1fr"};


  overflow-y: scroll;
  /* &::-webkit-scrollbar {
    display: none;
  } */
`
const StyledContentTitle = styled(motion.h4)`
  position: relative;
  font-size: 1.2rem;

  margin-bottom: 10px;

`
const StyledContentLocation = styled(motion.p)`
  position: relative;
  font-style: italic;
`
const StyledContentBody = styled(motion.div)`
  position: relative;
  line-height: 1.7rem;
  padding-bottom: 3rem;

  & p {
    margin-bottom: 20px;
  }

  & p.no_border {
    margin-bottom: 0px;
  }

  & ul {
    margin: 20px;

    & > * {
      margin-bottom: 10px;
    }
  }
`


const StyledContentCompany = styled(motion.h5)`
  font-size: 1rem;
  margin-bottom: 15px;
`

export const ProjectContainer = ({
  type,
  content,
}: {
  type: string
  content: { title: string; company: string; location: string; body: string }

}) => {
  const controls = useAnimation()

  React.useEffect(() => {
    controls.set({ opacity: 0, x: -100 })
    controls.start(i => ({
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.7,
        delay: i * 0.3,
      },
    }))
  }, [content])


  const isRE = type === "RE"

  return (
    <StyledItemContent isRE={isRE}>
      <StyledContentTitle custom={0} animate={controls}>
        {content.title}
      </StyledContentTitle>
      {isRE && (
        <StyledContentCompany custom={1} animate={controls}>
          {content.company}
        </StyledContentCompany>
      )}
      <StyledContentLocation custom={isRE ? 2 : 1} animate={controls}>
        {content.location}
      </StyledContentLocation>
      <StyledContentBody
        custom={isRE ? 3 : 2}

        animate={controls}
        dangerouslySetInnerHTML={{ __html: content.body }}
      ></StyledContentBody>
    </StyledItemContent>
  )
}
