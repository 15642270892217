import { motion, AnimatePresence } from "framer-motion"
import React from "react"
import styled from "styled-components"
import {
  StyledGrid,
  StyledMainHeader,
  StyledSectionContainer,
} from "../../../styles/pageStyles"
import { ProjectContainer } from "./ProjectContainer"
import { ProjectList } from "./ProjectList"
import { ProjectNav } from "./ProjectNav"
import { AppContext } from "../../../providers"

const ProjectsGrid = styled(StyledGrid)`
  margin-bottom: 400px;
`

export const StyledCardContainer = styled(motion.div)`
  background: white;
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 0 30px 0 30px;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.3);
  position: absolute;
  width: 97%;
  z-index: 0;
  border: 6px solid var(--secondcolor);

  /* @media screen and (min-width: 760px) {
  }
  @media screen and (min-width: 1040px) {
    padding: 20px;
  } */
  @media screen and (min-width: 1300px) {
    display: grid;
    min-height: 80vh;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 150px 1fr;
    grid-row-gap: 5px;
  }
`

const StyledBodyText = styled.p`
  font-size: clamp(14px, 1vw, 1rem);
  margin: 20px;
  margin-bottom: 2px;

  &:first-of-type {
    margin-top: 10px;
  }
`

export const DesktopProjectsView = () => {
  const {
    data,
    active,
    container,
    setActive,
    setContainer,
    setData,
  } = React.useContext(AppContext)

  return (
    <ProjectsGrid>
      <StyledMainHeader>Projects</StyledMainHeader>
      <ProjectNav isActive={container} setActive={setContainer} />
      <StyledSectionContainer>
        <AnimatePresence>
          {container === 0 && (
            <StyledCardContainer
              initial={{ x: -2000, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{
                type: "tween",
                duration: 0.8,
                //   bounce: 0.3,
                //   damping: 40,
              }}
              exit={{ x: -2000, opacity: 0 }}
            >
              <div>
                <StyledBodyText>
                  REAMC Limited is able to undertake work contracted directly
                  with Oil & Gas Companies; additionally, we have Associate
                  agreements with a number of other sub-surface consultancy
                  companies with whom we may undertake joint projects.
                </StyledBodyText>
                <StyledBodyText>
                  Past projects have included Due Diligence / Competent Persons
                  Reports in support of M&A activity, FDP review on behalf of
                  Government regulator, Peer Review / Technical Advice
                  concerning complex reservoir simulation models and acting as
                  an Expert Witness on Reservoir Engineering issues.
                </StyledBodyText>
              </div>
              <ProjectList
                isActive={active.RE}
                setIsActive={data => setActive("RE", data)}
                list={"RE"}
                setContent={data => setData("RE", data)}
              />

              <ProjectContainer type={"RE"} content={data.RE} />
            </StyledCardContainer>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {container === 1 && (
            <StyledCardContainer
              initial={{ x: 2000, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{
                type: "tween",
                duration: 0.8,
                // bounce: 0.3,
                // damping: 40,
              }}
              exit={{ x: 2000, opacity: 0 }}
            >
              <StyledBodyText>
                The main focus of our R&D work is related to the underlying
                mathematics of pseudo-random number generation methods,
                including both theoretical analysis and empirical testing; also
                applications including stochastic modelling methods and
                cryptography.
              </StyledBodyText>
              <ProjectList
                isActive={active.AM}
                setIsActive={data => setActive("AM", data)}
                list={"AM"}
                setContent={data => setData("AM", data)}
              />
              <ProjectContainer type={"AM"} content={data.AM} />

            </StyledCardContainer>
          )}
        </AnimatePresence>
      </StyledSectionContainer>
    </ProjectsGrid>
  )
}
