import * as React from "react"
import NavBarContext from "./context"
import styled from "styled-components"
import { useWindowScroll } from "./hooks"

type Props = {
  svg: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  left?: Boolean
  stroke?: string
  fill?: string
}

interface StyleProps {
  open: boolean
  stroke?: string
  fill?: string
}

const StyledSVG = styled.span`
  position: absolute;
  margin-right: 5vw;
  cursor: pointer;
  right: 0;

  & > svg {
    & > path {
      stroke: ${(props: StyleProps) => (props.open ? "white" : props.stroke)};
      transition: transform 300ms cubic-bezier(0.51, 0.92, 0.24, 1);

      &:nth-of-type(1) {
        transform: ${(props: StyleProps) =>
          props.open ? " translate(9px ,3px) rotatez(40deg) scaleX(0.7)" : ""};
      }
      &:nth-of-type(2) {
        transform-origin: center;
        transform: ${(props: StyleProps) =>
          props.open ? "scalex(0)" : "none"};
        opacity: ${(props: StyleProps) => {
          props.open ? 0 : 1
        }};
      }
      &:nth-of-type(3) {
        transform: ${(props: StyleProps) =>
          props.open ? "translate( -9px,3px) rotatez(-40deg) scaleX(0.7)" : ""};
      }
    }
  }

  @media screen and (min-width: 760px) {
    display: none;
  }
`

export const NavToggle = ({ svg: SVG, left, ...props }: Props) => {
  const { isNavOpen, setNavState, parentProps } = React.useContext(
    NavBarContext
  )
  const scrollPos = useWindowScroll()
  const [styles] = parentProps
  const handleClick = () => {
    setNavState(!isNavOpen)
  }

  return (
    <StyledSVG
      stroke={!!scrollPos ? "#515396" : styles.color}
      open={isNavOpen}
      onClick={handleClick}
    >
      <SVG {...props} width="35" />
    </StyledSVG>
  )
}
