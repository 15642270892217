import styled from "styled-components"

export const StyledCard = styled.div`
  padding: 20px;
  width: 95%;
  grid-row: 2 / span 2;
  border: 4px solid var(--secondcolor);
  background: white;
  border-radius: 0 40px 0 40px;
  box-shadow: 4px 8px 25px 2px rgba(0, 0, 0, 0.2);
  z-index: 2;
  position: relative;
  justify-self: center;
  margin-top: 70px;

  @media screen and (max-width: 760px) {
    padding: 20px;
  }
  @media screen and (min-width: 1040px) {
    width: 80%;
    /* margin-top: 0px; */
  }
`

export const StyledPicture = styled.div`
  width: 25vw;
  height: 25vw;
  background: white;
  border-radius: 100%;
  border: 4px solid var(--secondcolor);
  box-shadow: 0px 3px 20px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  min-width: 260px;
  min-height: 300px;
  max-width: 300px;
  max-height: 360px;
`

export const StyledText = styled.p`
  font-size: 1rem;
  max-width: 60em;
  line-height: 1.6rem;
  color: var(--text-dark);
  margin-bottom: 30px;

  &:first-of-type {
    margin-top: 350px;
  }

  @media screen and (min-width: 760px) {
    padding: 20px;
    margin-bottom: 5px;
  }
  /* @media screen and (min-width: 1040px) {
    margin: 20px;
  } */
  @media screen and (min-width: 1550px) {
    &:first-of-type {
      margin-top: 20px;
    }
  }
`

export const FlexedPictureBox = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -20%);
  max-width: 450px;
  max-height: 450px;
  min-width: 200px;
  min-height: 200px;
  text-align: center;
  align-items: center;
  justify-content: center;

  & p {
    color: var(--ternary);
  }

  @media screen and (min-width: 1550px) {
    position: absolute;
    left: 90%;
    right: 0;
    top: 0;
  }
`
