import { motion } from "framer-motion"
import React from "react"
import styled from "styled-components"

const FixedNavBar = styled.nav`
  position: fixed;
  display: flex;
  bottom: 0;
  justify-content: space-around;
  padding: 0.7rem 0.5rem;
  align-items: center;
  gap: 20px;
  background: #fff;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.3);

  z-index: 5;
  width: 100%;
`

interface Props {
  active: boolean
}

export const NavMenuItem = styled(motion.h3)`
  color: ${(props: Props) => (props.active ? "var(--primary)" : "#bbb")};
  font-style: italic;
  letter-spacing: 0.2rem;
  font-size: 0.8rem;
  text-transform: uppercase;
  line-height: 1.2rem;
  cursor: pointer;
  max-width: 10em;
`

export const MobileNav = ({ children }) => {
  return <FixedNavBar>{children}</FixedNavBar>
}
