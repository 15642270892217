import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const MobileTableContainer = styled.div`
  background: white;
  padding: 20px;
  border: 4px solid var(--secondcolor);
  margin-bottom: 40px;
  border-radius: 4px 20px 4px 20px;
  width: 90vw;

  & h4,
  h5 {
    margin-bottom: 4px;
  }

  & > div:not(:first-child) {
    border-top: 2px solid var(--secondary);
    margin-top: 20px;
    padding-top: 20px;
  }
`

const MobileTableCard = styled.div`
  & .btn {
    margin-top: 5px;
    justify-self: flex-end;
  }
`

interface Props {
  data: any[]
  heading: string
}
export const MobileTable = ({ data, heading }: Props) => {
  return (
    <MobileTableContainer>
      {data
        .sort(
          (a: any, b: any) => b.node.frontmatter.key - a.node.frontmatter.key
        )
        .map(({ node }, i) => (
          <MobileTableCard key={i}>
            <h4>
              {heading === "Report"
                ? node.frontmatter.report
                : node.frontmatter.location}
            </h4>
            <h5>
              <i>{node.frontmatter.title}</i>
            </h5>
            <p>
              <small>{node.frontmatter.date}</small>
            </p>
            <p>
              <small>{node.frontmatter.author}</small>
            </p>

            <Link
              className="btn"
              to={`/Publications/${node.fields.slug}`}
              state={{ previous: "/Publications" }}
            >
              <svg
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                height="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                ></path>
              </svg>
              Abstract
            </Link>
            <a className="btn" download href={`${node.frontmatter.download}`}>
              <svg
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                height="20"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                ></path>
              </svg>
              Download
            </a>
          </MobileTableCard>
        ))}
    </MobileTableContainer>
  )
}
