import styled from "styled-components"

interface StyledProps {
  columns: string
  rows: string
  gap?: string
  gapr?: string
  gapc?: string

  scolumns: string
  srows: string
  sgap?: string
  sgapr?: string
  sgapc?: string

  mcolumns: string
  mrows: string
  mgap?: string
  mgapr?: string
  mgapc?: string
}

export const Grid = styled.div`
  display: grid;
  position: absolute;
  width: 100vw;
  padding: 20px;
  padding-bottom: 0;
  grid-template-columns: 1fr;
  grid-template-rows: 150px 200px 150px 200px;
  gap: 10px;
  justify-items: center;

  @media screen and (min-width: 1550px) {
    grid-template-columns: ${(props: StyledProps) => props.columns};
    grid-template-rows: ${(props: StyledProps) => props.rows};
    gap: ${(props: StyledProps) => (props.gap ? props.gap : null)};
  }
`
