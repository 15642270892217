import * as React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import Img from "../../assets/images/rig.jpg"
import { useOnScreen, useWindowSize } from "../../hooks"

const StyledGrid = styled.div`
  display: grid;
  gap: 40px;
  justify-content: center;
  grid-template-columns: 1fr;
  padding: 20px;
  grid-template-rows: repeat(4, auto);
  padding-bottom: 100px;
  perspective: 1500px;

  @media screen and (min-width: 1550px) {
    width: 90%;
    grid-column-gap: 100px;
    grid-row-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: dense;
  }
`

const StyledImage = styled(motion.img)`
  object-fit: cover;
  width: 80%;
  max-width: 400px;
  min-width: 240px;
  grid-row: 3;
  grid-column: 1;
  justify-self: center;
  border-radius: 75px 0 75px 0;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);

  @media screen and (min-width: 760px) {
    width: 80%;
    height: 40vh;
    max-width: 600px;
    max-height: 375px;
    min-height: 350px;
  }

  @media screen and (min-width: 1550px) {
    width: 100%;
    height: 80%;
    max-width: 600px;
    max-height: 500px;
    align-self: center;
    grid-column: 1;
    grid-row: 1 / span 3;
  }
`

const StyledHeader = styled(motion.h2)`
  grid-column: 1;
  grid-row: 1;
  justify-self: center;
  text-transform: uppercase;

  color: var(--primary);

  font-size: 2.6rem;

  @media screen and (min-width: 760px) {
    font-size: 3.8rem;
  }

  @media screen and (min-width: 1550px) {
    font-size: 4rem;
    letter-spacing: 1.2rem;
    grid-column: 2;
    justify-self: start;
    align-self: center;
  }
`

const StyledBodyText = styled(motion.p)`
  justify-self: start;
  grid-column: 1;
  grid-row: 2;
  line-height: 1.6rem;
  color: var(--ternary);
  max-width: 400px;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.9rem;

  @media screen and (min-width: 760px) {
    font-size: 1.2rem;
    line-height: 2.5rem;
    margin: 50px 0;
    max-width: 600px;
  }

  @media screen and (min-width: 1040px) {
    max-width: 80%;
  }

  @media screen and (min-width: 1550px) {
    grid-column: 2;

    letter-spacing: 0.2rem;
    line-height: 2.6rem;
  }
`

const StyledList = styled(motion.ol)`
  justify-self: center;
  grid-column: 1;
  grid-row: 4;
  padding: 40px;
  margin-left: 40px;

  @media screen and (min-width: 1550px) {
    grid-column: 2;
    grid-row: 3;
    margin-left: 0;
    justify-self: center;
  }
`

const StyledListItem = styled(motion.li)`
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.5rem;
  color: var(--ternary);

  margin-bottom: 15px;

  ::before {
    content: "";
    width: 5px;
    display: inline-block;
  }

  @media screen and (min-width: 760px) {
    line-height: 2rem;
    max-width: 800px;
  }

  @media screen and (min-width: 1550px) {
    letter-spacing: 0.2rem;
    line-height: 2.5rem;

    ::before {
      content: "";
      width: 10px;
      display: inline-block;
    }
  }
`

export const About = () => {
  const { width } = useWindowSize()
  const [rotation, setRotation] = React.useState({
    y: width <= 740 ? 30 : 40,
    z: width <= 740 ? 0 : -15,
    x: width <= 740 ? 10 : 20,
  })

  React.useEffect(() => {
    setRotation({
      y: width <= 740 ? 30 : 40,
      z: width <= 740 ? -5 : -15,
      x: width <= 740 ? 10 : 20,
    })
  }, [width])
  const [imgref] = useOnScreen({ threshold: 0.9 })
  const [headerref, headervisible] = useOnScreen({
    threshold: 0.7,
  })
  const [textref, textvisible] = useOnScreen({
    threshold: 0.7,
  })
  const [listref, listvisible] = useOnScreen({
    threshold: 0.7,
  })
  const variants = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -30 },
    threedimvis: {
      opacity: 1,
      rotateX: rotation.x,
      rotateY: rotation.y,
      rotateZ: rotation.z,
    },
    threedimhide: { opacity: 0, rotateX: 0, rotateY: 0, rotateZ: 0 },
  }

  return (
    <StyledGrid>
      <StyledImage
        ref={imgref}
        variants={variants}
        initial="threedimhide"
        animate={textvisible ? "threedimvis" : "threedimhide"}
        transition={{
          type: "spring",
          delay: 0.3,
          duration: 0.7,
          mass: 40,
          damping: 100,
        }}
        src={Img}
      />
      <StyledHeader
        ref={headerref}
        variants={variants}
        initial="hidden"
        animate={headervisible ? "visible" : "hidden"}
        transition={{
          type: "tween",
          duration: 0.7,
        }}
      >
        Why Us?
      </StyledHeader>
      <StyledBodyText
        ref={textref}
        variants={variants}
        initial="hidden"
        animate={textvisible ? "visible" : "hidden"}
        transition={{
          type: "tween",
          duration: 0.7,
          delay: 0.2,
        }}
      >
        REAMC Limited helps clients find timely and practical solutions to
        complex engineering problems. Drawing on more than 40 years of energy
        and environment industry experience, we can help you to:
      </StyledBodyText>
      <StyledList ref={listref}>
        <StyledListItem
          variants={variants}
          initial="hidden"
          animate={listvisible ? "visible" : "hidden"}
          transition={{
            type: "tween",
            duration: 0.7,
            delay: 0.3,
          }}
        >
          Analyse and understand the problem
        </StyledListItem>
        <StyledListItem
          variants={variants}
          initial="hidden"
          animate={listvisible ? "visible" : "hidden"}
          transition={{
            type: "tween",
            duration: 0.7,
            delay: 0.4,
          }}
        >
          Develop and implement a strategy for its solution
        </StyledListItem>
        <StyledListItem
          variants={variants}
          initial="hidden"
          animate={listvisible ? "visible" : "hidden"}
          transition={{
            type: "tween",
            duration: 0.7,
            delay: 0.5,
          }}
        >
          Report results and conclusions in a clear and understandable way
        </StyledListItem>
      </StyledList>
    </StyledGrid>
  )
}
