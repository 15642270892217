import React from "react"
import styled from "styled-components"
import { StyledButton } from "../Button"
import { motion } from "framer-motion"
import { useOnScreen } from "../../hooks"
import { FormFields } from "../Contact/Contact"

export const StyledForm = styled(motion.form)`
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(5, 40px) 175px;
  gap: 20px;

  @media screen and (min-width: 760px) {
    grid-template-rows: repeat(4, 40px) 175px;
  }
`
export const StyledLabel = styled(motion.label)`
  position: absolute;
  top: 10px;
  left: 10px;
  transition: transform 300ms var(--bezier), background 300ms var(--bezier);
`
export const StyledFormGroup = styled(motion.div)`
  position: relative;

  &:nth-of-type(1) {
    grid-column: 1 / span 2;
  }
  &:nth-of-type(2) {
    grid-column: 1 / span 2;
  }
  &:nth-of-type(3) {
    grid-column: 1 / span 2;
  }
  &:nth-of-type(4) {
    grid-column: 1 / span 2;
  }
  &:nth-of-type(5) {
    grid-column: 1 / span 2;
  }
  &:nth-of-type(6) {
    grid-column: 1 / span 2;
  }
  @media screen and (min-width: 760px) {
    &:nth-of-type(1) {
      grid-column: 1;
    }
    &:nth-of-type(2) {
      grid-column: 2;
      grid-row: 1;
    }
  }
`
const StyledInput = styled(motion.input)`
  position: relative;
  width: 100%;
  height: 100%;
  border: 2px solid var(--primary);
  border-radius: 0 14px 0 0;
  padding-left: 10px;
  font-size: 1.1rem;
  letter-spacing: 0.1rem;
  &:focus {
    border: 3px solid var(--active);
    outline: none;

    & ~ label {
      background: white;
      transform: translate(-10px, -20px) scale(0.9);
    }
  }

  &:required:placeholder-shown ~ label::after {
    content: "*";
    font-size: 1.2rem;
    margin-left: 5px;
    color: red;
    background: white;
  }

  &:not(:placeholder-shown):valid {
    border: 2px solid var(--success);
  }

  &:invalid {
    box-shadow: none;
  }

  &:not(:placeholder-shown) ~ label {
    background: white;
    transform: translate(-10px, -20px) scale(0.9);
  }
`

const StyledFirstNameInput = styled(StyledInput)``

const StyledSurNameInput = styled(StyledInput)``

const StyletSubjectSelect = styled(motion.select)`
  width: 100%;
  height: 100%;
  padding-left: 6px;
  background: transparent;
  border: 2px solid var(--primary);
  border-radius: 0 14px 0 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-family: montserrat;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%;
  background-size: 0.65em auto;
  font-size: 1rem;

  &:focus {
    border: 3px solid var(--active);
    outline: none;
  }

  &:valid {
    border: 2px solid var(--primary);
  }
`

const StyledCompanyInput = styled(StyledInput)``
const StyledEmailInput = styled(StyledInput)`
  &:not(:placeholder-shown):invalid {
    border: 2px solid var(--warning);
  }

  &:valid {
    border: 2px solid var(--success);
  }
`

const StyledTextArea = styled.textarea`
  width: 100%;
  height: 100%;
  resize: none;
  border: 2px solid var(--primary);
  border-radius: 0 14px 0 0;
  padding: 10px;
  font-family: montserrat;
  font-size: 1.1rem;
  letter-spacing: 0.1rem;

  &:focus {
    border: 3px solid var(--active);
    outline: none;

    & ~ label {
      background: white;
      transform: translate(-10px, -20px) scale(0.9);
    }
  }

  &:required:placeholder-shown ~ label::after {
    content: "*";
    font-size: 1.2rem;
    margin-left: 5px;
    color: red;
    background: white;
  }

  &:valid {
    border: 2px solid var(--success);
  }

  &:invalid {
    box-shadow: none;
  }

  &:not(:placeholder-shown) ~ label {
    background: white;
    transform: translate(-10px, -20px) scale(0.9);
  }
`

interface Props {
  handleChange: React.Dispatch<FormFields>
}

const StyledSubmitButton = styled(StyledButton)``

export const Form = ({ handleChange }) => {
  const [firstref, firstvisible] = useOnScreen({
    threshhold: 0.7,
  })
  const [lastref, lastvisible] = useOnScreen({
    threshhold: 0.7,
  })
  const [companyref, companyvisible] = useOnScreen({
    threshhold: 0.7,
  })
  const [emailref, emailvisible] = useOnScreen({
    threshhold: 0.7,
  })
  const [subjectref, subjectvisible] = useOnScreen({
    threshhold: 0.7,
  })
  const [bodyref, bodyvisible] = useOnScreen({
    threshhold: 0.7,
  })
  const [btnref, btnvisible] = useOnScreen({
    threshhold: 0.7,
  })

  const variants = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -30 },
  }

  const btnVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }
  return (
    <>
      <StyledFormGroup
        ref={firstref}
        variants={variants}
        initial="hidden"
        animate={firstvisible ? "visible" : "hidden"}
        transition={{ type: "tween", duration: 0.5, delay: 0.2 }}
      >
        <StyledFirstNameInput
          required
          type="text"
          id="firstname"
          name="firstname"
          placeholder=" "
          onChange={handleChange}
        />
        <StyledLabel htmlFor="firstname">Firstname</StyledLabel>
      </StyledFormGroup>
      <StyledFormGroup
        ref={lastref}
        variants={variants}
        initial="hidden"
        animate={lastvisible ? "visible" : "hidden"}
        transition={{ type: "tween", duration: 0.5, delay: 0.2 }}
      >
        <StyledSurNameInput
          name="surname"
          onChange={handleChange}
          required
          type="text"
          id="surname"
          placeholder=" "
        />
        <StyledLabel htmlFor="surname">Surname</StyledLabel>
      </StyledFormGroup>
      <StyledFormGroup
        ref={companyref}
        variants={variants}
        initial="hidden"
        animate={companyvisible ? "visible" : "hidden"}
        transition={{ type: "tween", duration: 0.5, delay: 0.3 }}
      >
        <StyledCompanyInput
          name="company"
          onChange={handleChange}
          type="text"
          id="company"
          placeholder=" "
        />
        <StyledLabel htmlFor="company">Company</StyledLabel>
      </StyledFormGroup>
      <StyledFormGroup
        ref={emailref}
        variants={variants}
        initial="hidden"
        animate={emailvisible ? "visible" : "hidden"}
        transition={{ type: "tween", duration: 0.5, delay: 0.4 }}
      >
        <StyledEmailInput
          name="email"
          onChange={handleChange}
          required
          type="email"
          id="email"
          placeholder=" "
        />
        <StyledLabel htmlFor="email">Email</StyledLabel>
      </StyledFormGroup>
      <StyledFormGroup
        ref={subjectref}
        variants={variants}
        initial="hidden"
        animate={subjectvisible ? "visible" : "hidden"}
        transition={{ type: "tween", duration: 0.5, delay: 0.5 }}
      >
        <StyletSubjectSelect
          onChange={handleChange}
          required
          name="subject"
          id="subject"
          defaultValue="default"
        >
          <option value="default" disabled>
            Please Select a Subject... *
          </option>
          <option value="Reservoir Engineering">Reservoir Engineering</option>
          <option value="ACORN Random Number Generation">
            ACORN Random Number Generation
          </option>
          <option value="Mathematical Modeling">Mathematical Modelling</option>
          <option value="REAMC Limited Publications">
            REAMC Limited Publications
          </option>
          <option value="General Enquiry">General Enquiry</option>
        </StyletSubjectSelect>
      </StyledFormGroup>
      <StyledFormGroup
        ref={bodyref}
        variants={variants}
        initial="hidden"
        animate={bodyvisible ? "visible" : "hidden"}
        transition={{ type: "tween", duration: 0.5, delay: 0.6 }}
      >
        <StyledTextArea
          required
          onChange={handleChange}
          name="message"
          id="message"
          cols={30}
          rows={10}
          placeholder=" "
        ></StyledTextArea>
        <StyledLabel htmlFor="message">...write your message</StyledLabel>
      </StyledFormGroup>
      <StyledSubmitButton
        ref={btnref}
        border="var(--primary)"
        background="transparent"
        altcolor="#fff"
        type="submit"
        value="Submit"
        variants={btnVariants}
        initial="hidden"
        animate={btnvisible ? "visible" : "hidden"}
        transition={{ type: "tween", duration: 0.5, delay: 0.7 }}
      >
        Submit
      </StyledSubmitButton>
      <motion.p
        style={{ color: "red", justifySelf: "flex-end" }}
        variants={variants}
        initial="hidden"
        animate={btnvisible ? "visible" : "hidden"}
        transition={{ type: "tween", duration: 0.5, delay: 0.8 }}
      >
        * required
      </motion.p>
    </>
  )
}
