import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { StyledButton } from "../Button"
import { motion } from "framer-motion"
import { useOnScreen } from "../../hooks"
import scrollTo from "gatsby-plugin-smoothscroll"

const StyledLink = styled(Link)`
  display: inline-block;
  width: 150px;
  padding: 8px 10px;
  border: 2px solid white;
  color: white;
  text-align: center;
  text-transform: uppercase;
  border-radius: 5px;
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-size: 0.8rem;
  transition: background 300ms ease-in-out, color 300ms ease-in-out;

  &:hover {
    background: white;
    color: var(--primary);
  }
`

const StyledGrid = styled.div`
  display: grid;
  gap: 50px;
  justify-content: center;
  grid-template-columns: 1fr;
  padding: 20px;
  grid-template-rows: repeat(4, auto);
  padding-bottom: 100px;

  @media screen and (min-width: 760px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }

  @media screen and (min-width: 1550px) {
    width: 90%;
    grid-column-gap: 40px;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: dense;
    padding-bottom: 100px;
  }
`

const StyledCard = styled(motion.div)`
  background: var(--gradientdark);
  position: relative;
  align-self: center;
  justify-self: center;
  width: 95%;
  max-width: 600px;
  min-width: 360px;
  min-height: 300px;
  grid-column: 1;
  padding: 40px 30px;
  color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
  padding-bottom: 80px;

  &:first-of-type {
    margin-bottom: 20px;
    border-radius: 0 45px 0 0;
  }
  &:last-of-type {
    border-radius: 0 0 0 45px;
  }

  & ul {
    margin: 0 15px;
  }

  @media screen and (min-width: 1040px) {
    max-width: 650px;
    padding: 60px;
    height: 620px;

    &:first-of-type {
      margin-bottom: 0;
      border-radius: 0 0 0 45px;
    }

    &:last-of-type {
      border-radius: 0 45px 0 0;
    }
  }

  @media screen and (min-width: 1140px) {
    height: 550px;
  }

  @media screen and (min-width: 1380px) {
    height: 500px;
  }
`

const StyledCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 740px) {
    grid-column: 1 / span 2;
  }

  @media screen and (min-width: 1040px) {
    flex-direction: row;
    grid-row: 2;
    grid-column: 1 / span 2;
    justify-content: center;
    gap: 40px;
  }
`

const StyledCardHeader = styled(motion.h3)`
  margin-bottom: 10px;
  align-self: flex-start;
  justify-self: center;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;

  @media screen and (min-width: 1040px) {
    font-size: clamp(1.7rem, 4vw, 2.3rem);
    margin-top: -20px;
  }
`
const StyledCardBody = styled(motion.p)`
  text-align: left;

  line-height: 1.5rem;
  word-spacing: 0.2rem;
  font-size: 1rem;
  margin-bottom: 12px;

  /* :first-of-type {
    margin-top: 20px;
  } */

  @media screen and (min-width: 760px) {
    line-height: 1.9rem;
  }
`
const StyledHeader = styled(motion.h2)`
  grid-column: 1;
  grid-row: 1;
  justify-self: center;
  margin-top: 70px;
  text-transform: uppercase;

  color: var(--primary);

  font-size: 2.6rem;

  @media screen and (min-width: 760px) {
    grid-row: 1;
    grid-column: 1 / span 2;
    font-size: 4rem;
  }

  @media screen and (min-width: 1550px) {
    font-size: 4rem;
    letter-spacing: 1.2rem;
    grid-column: 1 / span 2;
    justify-self: center;
    align-self: center;
  }
`
const StyledCTA = styled(motion.h4)`
  text-align: center;
  align-self: center;
  justify-self: center;
  color: var(--primary);
  font-size: 1.4rem;

  word-spacing: 0.4rem;

  @media screen and (min-width: 760px) {
    font-size: 1.7rem;
    grid-row: 3;
    grid-column: 1 / span 2;
  }
`
const StyledBody = styled(motion.p)`
  text-align: center;
  align-self: center;
  justify-self: center;
  color: var(--ternary);
  font-size: 1rem;
  line-height: 1.7rem;
  word-spacing: 0.1rem;
  max-width: 1300px;

  @media screen and (min-width: 760px) {
    font-size: 1.2rem;
    grid-row: 4;
    grid-column: 1 / span 2;
    line-height: 2rem;
  }

  @media screen and (min-width: 1550px) {
    line-height: 1.9rem;
  }
`
const StyledContactButton = styled(StyledButton)`
  align-self: center;
  justify-self: center;

  @media screen and (min-width: 760px) {
    grid-row: 5;
    grid-column: 1 / span 2;
  }
`

const StyledlistItem = styled.li``

export const Services = () => {
  const [headerref, headervisible] = useOnScreen({
    threshold: 0.5,
  })
  const [card1ref, card1visible] = useOnScreen({
    threshold: 0.2,
  })
  const [card2ref, card2visible] = useOnScreen({
    threshold: 0.2,
  })
  const [ctaref, ctavisible] = useOnScreen({
    threshold: 0.4,
  })
  const [bodyref, bodyvisible] = useOnScreen({
    threshold: 0.4,
  })
  const [btnref, btnvisible] = useOnScreen({
    threshold: 0.4,
  })

  const variants = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -50 },
  }

  const btnVariants = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -30 },
  }

  return (
    <StyledGrid id="services">
      <StyledHeader
        ref={headerref}
        variants={variants}
        initial="hidden"
        animate={headervisible ? "visible" : "hidden"}
        transition={{
          type: "tween",
          duration: 0.5,
        }}
      >
        Services
      </StyledHeader>
      <StyledCardContainer>
        <StyledCard
          ref={card1ref}
          whileHover={{
            scale: 1.02,
            transition: {
              type: "spring",
              mass: 0.3,
              damping: 4,
            },
          }}
          variants={variants}
          initial="hidden"
          animate={card1visible ? "visible" : "hidden"}
          transition={{
            type: "tween",
            duration: 0.5,
            delay: 0.2,
          }}
        >
          <StyledCardHeader>Reservoir Engineering</StyledCardHeader>
          <StyledCardBody>
            Field development plans and complex simulation studies – peer
            review, technical consultancy and trouble-shooting.
          </StyledCardBody>
          <StyledCardBody>
            Oil and gas reserves – review, audit and evaluation.
          </StyledCardBody>
          <StyledCardBody>
            M&A activity - RE evaluation and support including red flag review,
            detailed assessment, Competent Person’s reports.
          </StyledCardBody>
          <StyledCardBody>
            Carbon sequestration - technical review/audit of development plans,
            technical risk assessment of CO2 storage and CO2 enhanced oil
            recovery.
          </StyledCardBody>
          <StyledLink
            to="/Projects"
            state={{ project: "Engineering" }}
            style={{ textDecoration: "none" }}
          >
            Go to Projects
          </StyledLink>
        </StyledCard>

        <StyledCard
          ref={card2ref}
          whileHover={{
            scale: 1.02,
            transition: {
              type: "spring",
              mass: 0.3,
              damping: 4,
            },
          }}
          variants={{
            hidden: { x: 50, opacity: 0 },
            visible: {
              x: 0,
              opacity: 1,
            },
          }}
          initial="hidden"
          animate={card2visible ? "visible" : "hidden"}
          transition={{
            type: "tween",
            duration: 0.5,
            delay: 0.2,
          }}
        >
          <StyledCardHeader>Applied Mathematics</StyledCardHeader>
          <StyledCardBody>
            Numerical and analytical modelling consultancy to solve real-world
            problems including
          </StyledCardBody>
          <ul>
            <StyledlistItem>
              <StyledCardBody>
                Fluid flow in oil reservoirs and other geological formations
              </StyledCardBody>
            </StyledlistItem>
            <StyledlistItem>
              <StyledCardBody>
                Radioactive waste management and related safety assessment
              </StyledCardBody>
            </StyledlistItem>
          </ul>
          <StyledCardBody>
            Current research topics include methods for uniform pseudo-random
            number generation (in particular, the ACORN generator) and
            applications of ACORN to Cryptography.
          </StyledCardBody>

          <StyledLink
            to="/Projects"
            state={{ project: "Maths" }}
            style={{ textDecoration: "none" }}
          >
            Go to Projects
          </StyledLink>
        </StyledCard>
      </StyledCardContainer>

      <StyledCTA
        ref={ctaref}
        variants={variants}
        initial="hidden"
        animate={ctavisible ? "visible" : "hidden"}
        transition={{
          type: "tween",
          duration: 1,
          delay: 0.2,
        }}
      >
        Built on more than 40 years experience of Consultancy and Research in
        Oil & Gas, Nuclear and Environmental Industries.
      </StyledCTA>
      <StyledBody
        ref={bodyref}
        variants={variants}
        initial="hidden"
        animate={bodyvisible ? "visible" : "hidden"}
        transition={{
          type: "tween",
          duration: 1,
          delay: 0.3,
        }}
      >
        If you have an engineering or mathematical problem that requires expert
        analysis and a practical solution, then please contact us to discuss
        your requirements and to map out a route to the solution you require.{" "}
        <br /> <br /> If you are interested in Cryptographic applications,
        please contact us for more information about our current research and/or
        to discuss how you might get involved in supporting this work.
      </StyledBody>
      <StyledContactButton
        ref={btnref}
        onClick={() => scrollTo("#contact")}
        border="var(--primary)"
        background="transparent"
        altcolor="#fff"
        variants={btnVariants}
        initial="hidden"
        animate={btnvisible ? "visible" : "hidden"}
        transition={{
          type: "tween",
          duration: 1,
          delay: 0.4,
        }}
      >
        Contact
      </StyledContactButton>
    </StyledGrid>
  )
}
