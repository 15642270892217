import { motion, useAnimation } from "framer-motion"
import React from "react"
import styled from "styled-components"

interface MenuProps {
  active: boolean
}

const StyledMenuItem = styled(motion.div)`
  width: 100%;

  box-shadow: 0 0px 4px 1px rgba(0, 0, 0, 0.22);
  padding: 20px;
  font-family: "Montserrat";
  font-weight: 600;
  margin-bottom: 6px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;

  & p {
    max-width: 40em;
    margin-bottom: 1%;
  }

  & span {
    position: absolute;
    right: 15px;
    bottom: 5px;
    font-size: 0.8rem;
    color: #c2c2c2;
  }


  &:after {
    content: "";
    position: absolute;
    background: var(--secondcolor);
    height: 90%;
    border-radius: 100px;
    width: 7px;
    margin: 4px;
    top: 0;
    right: 0;
    visibility: ${(props: MenuProps) => (props.active ? "visible" : "hidden")};
  }
`

interface Props {
  id: string
  preload: boolean
  isActive: false | string
  setIsActive: React.Dispatch<false | string>
  title: string

  company: string
  location: string
  body: string
  children: React.ReactNode
  onClick: (...args: any[]) => any
}

export const ProjectListItem = ({
  title,
  company,
  location,
  body,
  onClick,
  children,
  id,
  isActive,
  setIsActive,
}: Props) => {
  const [state] = React.useState({
    title,
    company,
    location,
    body,
  })

  const controls = useAnimation()

  const IsThisItemActive = isActive === id

  // React.useEffect(() => {
  //   preload &&
  //     onClick({
  //       company: state.company,
  //       location: state.location,
  //       body: state.body,
  //     })
  //   preload && setIsActive(id)
  // }, [])

  const handleOnClick = () => {
    controls.start({ opacity: 0 }).then(() => {
      onClick({
        title: state.title,
        company: state.company,
        location: state.location,
        body: state.body,
      })

      setIsActive(id)
    })
  }

  return (
    <StyledMenuItem
      animate={{
        scale: IsThisItemActive ? 1.02 : 1,
        backgroundColor: IsThisItemActive ? "#e2f4fb" : "#fff",
      }}
      active={IsThisItemActive}
      onClick={handleOnClick}
    >
      <p>{children}</p>
      <motion.span
        animate={{
          x: IsThisItemActive ? 10 : 0,
          opacity: IsThisItemActive ? 0 : 1,
        }}
      >
        read more...
      </motion.span>

    </StyledMenuItem>
  )
}
