import React from "react"
import { Link, navigate } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"

interface Props {
  children: React.ReactNode
  path: string
}

export const FooterLink = ({ children, path, ...props }: Props) => {
  return (
    <Link
      {...props}
      onClick={() => {
        console.log(window.location.pathname)
        window.location.pathname !== "/"
          ? navigate(path)
          : path[1] === "#"
          ? scrollTo(path.slice(1))
          : console.log(":(")
      }}
      to={path}
    >
      {children}
    </Link>
  )
}
